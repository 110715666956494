<template>
  <div class="tc-select">
    <!-- 筛选 -->
    <div class="filtrate">
      <div class="header-title">
        <p>筛选</p>
      </div>
      <div class="header-from">
        <el-form
          :inline="true"
          :model="formInline"
          class="demo-form-inline"
          size="mini"
          ref="formInline"
        >
          <el-form-item label="问卷分类" prop="qtid">
            <el-select v-model="formInline.qtid">
              <el-option
                :label="item.title"
                :value="item.id"
                v-for="(item, key) in classData"
                :key="key"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="题型" prop="type">
            <el-select v-model="formInline.type">
              <el-option label="单选题" :value="1"></el-option>
              <el-option label="多选题" :value="2"></el-option>
              <el-option label="判断题" :value="3"></el-option>
              <el-option label="填空题" :value="4"></el-option>
              <el-option label="排序题" :value="5"></el-option>
              <el-option label="计算题" :value="6"></el-option>
              <el-option label="解答题" :value="7"></el-option>
              <el-option label="问答题" :value="8"></el-option>
              <el-option label="作文题" :value="9"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="难度" prop="grade">
            <el-select v-model="formInline.grade">
              <el-option label="容易" :value="1"></el-option>
              <el-option label="中等" :value="2"></el-option>
              <el-option label="偏难" :value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="星级" prop="lv">
            <el-select v-model="formInline.lv">
              <el-option label="1星" :value="1"></el-option>
              <el-option label="2星" :value="2"></el-option>
              <el-option label="3星" :value="3"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button plain @click="loadListData()">查询</el-button>
            <el-button @click="resetForm('formInline')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 分数统计 -->
    <div class="statistics">
      <el-row v-if="typeNum.length <= 0" type="flex" justify="center"
        >-没有选择题目-</el-row
      >
      <el-row>
        <el-col
          :span="4"
          v-for="(item, key) in typeNum"
          :key="key"
          style="margin-top: 5px"
        >
          <span>{{ item.type | filterType }}-</span>已选择
          <span>{{ item.num }} 道-</span>
          <span>
            <el-input v-model="typeNum[key].total" size="small"></el-input>
          </span>
          <span>-总分</span>
        </el-col>
      </el-row>
    </div>
    <!-- 表格 -->
    <div class="content">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @selection-change="handleSelectionChange"
        stripe
        border
        max-height="600"
        class="tableBox"
      >
        <el-table-column
          type="selection"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="title"
          label="题目名称"
          width="550"
          align="left"
          header-align="center"
        ></el-table-column>
        <el-table-column
          prop="type"
          label="题目类型"
          width="200"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.type | filterType }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="qtid"
          label="所属分类"
          width="200"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.qtid | filterClass }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="share" label="共享" width="130" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.share | filterShare }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <!-- <el-button size="mini" type="plain" @click="goReport(scope.row.id)">查看</el-button> -->
            <!-- <el-divider direction="vertical"></el-divider> -->
            <el-button
              size="mini"
              type="plain"
              @click="showAddtestPaper(scope.row)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 添加按钮 -->
    <div class="btn">
      <el-row type="flex" justify="center">
        <el-button type="primary" @click="addItem">添加题目</el-button>
        <el-button type="primary" @click="back">返回</el-button>
      </el-row>
    </div>
    <!-- 页码 -->
    <div class="page">
      <!-- @size-change="handleSizeChange"-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="1"
        :page-size="15"
        :page-sizes="[15, 30, 50, 100, 200, 400]"
        layout="total,sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <!-- 添加试卷 -->
    <el-dialog
      :close-on-click-modal="false"
      :title="title"
      :visible.sync="addtestPaper"
      width="60%"
      center
      @close="reset"
    >
      <el-form ref="form" :model="form" label-width="120px" size="mini">
        <el-row>
          <el-col :span="8">
            <el-form-item label="分类：">
              <el-select v-model="form.qtid" placeholder>
                <el-option
                  :label="item.title"
                  :value="item.id"
                  v-for="(item, key) in classData"
                  :key="key"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="题型：">
              <el-select v-model="form.type" placeholder>
                <el-option label="单选题" :value="1"></el-option>
                <el-option label="多选题" :value="2"></el-option>
                <el-option label="判断题" :value="3"></el-option>
                <el-option label="填空题" :value="4"></el-option>
                <el-option label="排序题" :value="5"></el-option>
                <el-option label="计算题" :value="6"></el-option>
                <el-option label="解答题" :value="7"></el-option>
                <el-option label="问答题" :value="8"></el-option>
                <el-option label="作文题" :value="9"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="难易程度：">
              <el-select v-model="form.lv" placeholder>
                <el-option label="1星" :value="1"></el-option>
                <el-option label="2星" :value="2"></el-option>
                <el-option label="3星" :value="3"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="试题评级：">
              <el-select v-model="form.grade" placeholder>
                <el-option label="容易" :value="1"></el-option>
                <el-option label="中等" :value="2"></el-option>
                <el-option label="偏难" :value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="是否共享：">
              <el-radio-group v-model="form.share">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="题目：">
          <el-input
            v-if="form.type == 4"
            type="textarea"
            :rows="2"
            placeholder="示例:
中国的英文单词是( )，而美国的英文单词是( )
"
            v-model="form.title"
          ></el-input>
          <span v-if="form.type == 4"
            >说明：请把填空的文字用( )括起来，可以设置多项</span
          >
          <!-- 其他 -->
          <el-input
            v-if="form.type != 4"
            type="textarea"
            :rows="2"
            v-model="form.title"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="题目参数："
          v-if="form.type == 1 || form.type == 2 || form.type == 5"
        >
          <!-- 单选，多选，判断 -->
          <el-input
            type="textarea"
            :rows="5"
            placeholder="示例：
a:今天星期一
b:今天星期二
c:今天星期三
          "
            v-model="form.parameter"
          ></el-input>
          <span>说明：每个选项占一行，适用于:单选，多选，排序</span>
        </el-form-item>
        <el-form-item label="答案：">
          <!-- 单选，多选，判断 -->
          <el-input
            type="textarea"
            :rows="5"
            placeholder="示例:
a,b,c"
            v-model="form.answer"
            v-if="form.type == 1 || form.type == 2 || form.type == 5"
          ></el-input>
          <span v-if="form.type == 1 || form.type == 2 || form.type == 5"
            >说明：多个答案用逗号隔开</span
          >
          <!-- 填空 -->
          <el-input
            type="textarea"
            :rows="5"
            placeholder="示例:"
            v-model="form.answer"
            v-if="form.type == 4"
          ></el-input>
          <span v-if="form.type == 4"
            >说明：如果是多项填空,则每项的答案换一行.顺序不能乱</span
          >
          <!-- 其他 -->
          <el-input
            type="textarea"
            :rows="5"
            v-model="form.answer"
            v-if="
              form.type != 4 &&
              form.type != 1 &&
              form.type != 2 &&
              form.type != 5
            "
          ></el-input>
          <span
            v-if="
              form.type != 4 &&
              form.type != 1 &&
              form.type != 2 &&
              form.type != 5
            "
            >说明：直接填写正确的答案</span
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addtestPaper = false">取 消</el-button>
        <el-button type="primary" @click="addQuestion">{{ btnName }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
let that;
import {
  getQue,
  deleteQue,
  saveQue,
  getType,
  saveQuesub,
} from "@/api/questionnaire.js";
export default {
  data() {
    return {
      form: {
        qtid: "",
        type: "",
        title: "",
        parameter: "",
        answer: "",
        lv: "",
        grade: "",
        share: "",
        id: 0,
      },
      tableData: [],
      tmp: [],
      formInline: {},
      addtestPaper: false,
      // 页码
      page: 1,
      pageSize: 15,
      total: 0,
      // 分类数据
      classData: [],
      title: "添加题目",
      btnName: "添加",
      multipleSelection: "",
      typeNum: [],
    };
  },
  created() {
    this.loadListData();
    this.getClass();
  },
  beforeCreate: function () {
    that = this;
  },
  methods: {
    // 页码
    handleCurrentChange(val) {
      //console.log(val - 1);
      this.page = val;
      this.loadListData();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.loadListData();
    },
    // 全选
    handleSelectionChange(val) {
      //console.log(val);

      this.multipleSelection = val;
      let p = []; //所选数据 ID
      // let typeNum = [{ "1": [1, 20] }, { "2": [1, 20] }];
      let typeNum = [];
      this.multipleSelection.forEach((v, k) => {
        p.push(v.id); //id
        // 处理已选题目 和  总分
        let obj = { type: v.type, num: 1, total: 0 };
        //  1-判断 是否 存在 键值
        let tmp = typeNum.some((el) => {
          return el.type == v.type;
        });
        if (tmp) {
          typeNum.forEach((el) => {
            if (el.type == v.type) {
              el.num += 1;
            }
          });
        } else {
          typeNum.push(obj);
        }
      });

      // 保存至Data中

      this.typeNum = typeNum;
      //console.log(this.typeNum);
      this.multipleSelection = p.toString();
    },
    // 重置
    resetForm(formName) {
      this.$nextTick(() => {
        this.$refs[formName].resetFields();
      });
    },
    // 添加 、 编辑试卷题目模态框
    showAddtestPaper(row) {
      this.addtestPaper = true;
      this.btnName = "添加";
      this.title = "添加题目";
      if (row.id) {
        this.btnName = "修改";
        this.title = "编辑题目";
        this.form = row;
        delete this.form.update_time;
        delete this.form.create_time;
        this.form.parameter = row.parameter.join("\n");
      }
    },
    // 加载列表
    loadListData() {
      let data = {
        page: this.page,
        pagesize: this.pageSize,
        type: this.formInline.type,
        lv: this.formInline.lv,
        grade: this.formInline.grade,
        qtid: this.formInline.qtid,
      };
      getQue(data).then((res) => {
        this.tableData = res.data;
        this.total = res.num;
        this.tmp = JSON.parse(JSON.stringify(res.data));
      });
    },
    // 获取试题分类
    getClass() {
      let data = {
        page: 0,
        pagesize: 999999,
      };
      getType(data).then((res) => {
        this.classData = res.data;
      });
    },
    // 添加试题
    addQuestion() {
      //console.log(this.form);
      if (this.title == "添加题目") {
        delete this.form.id;
        saveQue(this.form).then((res) => {
          if (res.code == 400200) {
            this.$message({
              showClose: true,
              message: "添加成功,请继续添加",
              type: "success",
            });
            this.form.title = "";
            this.form.parameter = "";
            this.form.answer = "";
          }
        });
      } else {
        saveQue(this.form).then((res) => {
          if (res.code == 400200) {
            this.$message({
              showClose: true,
              message: "修改成功！",
              type: "success",
            });
            this.btnName = "继续添加";
            this.loadListData();
            this.addtestPaper = false;
          }
        });
      }
    },
    // 重置模态框
    reset() {
      this.form.qtid = "";
      this.form.type = "";
      this.form.title = "";
      this.form.parameter = "";
      this.form.answer = "";
      this.form.lv = "";
      this.form.grade = "";
      this.form.share = "";
      this.form.id = 0;
      this.tableData = JSON.parse(JSON.stringify(this.tmp));
      this.isCode = false;
      this.loadListData();
    },
    // 刷新
    refresh() {
      this.reload();
    },
    // 返回
    back() {
      this.$router.go(-1); //返回上一层
    },
    // 添加
    addItem() {
      let id = this.$route.query.id;
      let data = {
        id,
        qids: this.multipleSelection,
        title: this.$route.query.title,
        cores: this.typeNum,
      };
      saveQuesub(data).then((res) => {
        if (res.code == 400200) {
          this.$message({
            showClose: true,
            message: `已成功添加至${this.$route.query.title}试卷中`,
            type: "success",
          });
          this.$refs.multipleTable.clearSelection();
        }
      });
    },
  },
  filters: {
    filterClass(val) {
      let tmp = "";
      that.classData.forEach((v) => {
        if (v.id == val) {
          tmp = v.title;
        }
      });
      return tmp;
    },
    filterType(val) {
      let arr = [
        "单选题",
        "多选题",
        "判断题",
        "填空题",
        "排序题",
        "计算题",
        "解答题",
        "问答题",
        "作文题",
      ];
      return arr[val - 1];
    },
    filterShare(val) {
      return val == 1 ? "共享" : "不共享";
    },
  },
};
</script>

<style lang="less">
.tc-select {
  // 筛选
  .filtrate {
    display: flex;
    width: 100%;
    height: 50px;
    background: rgb(217, 242, 247);
    margin-top: 20px;
    .header-title {
      color: rgb(4, 87, 102);
      width: 56px;
      text-align: center;
      border-right: 1px solid rgb(127, 222, 243);
    }
    p {
      line-height: 50px;
      font-size: 14px;
    }
    .header-from {
      padding-left: 10px;
      display: flex;
      align-items: center;
      .el-input__inner {
        width: 100px;
        padding-right: 20px;
      }
      .el-form-item {
        margin-bottom: 0;
      }
      .el-form-item__label {
        color: rgb(4, 87, 102);
      }
      .el-input__icon {
        width: 15px;
      }
      .el-button--mini {
        padding: 5px 10px;
        background: transparent;
        color: rgb(4, 87, 102);
        font-size: 16px;
        &:hover {
          border: 1px solid rgb(105, 105, 105);
        }
      }
    }
  }
  //   表格
  .content {
    position: relative;
    height: calc(~"100% - 220px");
    min-height: 600px;
    margin-top: 20px;
    color: rgb(4, 87, 102);
    th {
      color: rgb(4, 87, 102);
      padding: 0;
      height: 40px;
      line-height: 40px;
      background: rgb(217, 242, 247);
    }
    td {
      padding: 0;
      height: 35px;
      line-height: 35px;
    }
    tr.el-table__row--striped td {
      background: rgb(234, 246, 248);
    }
    .el-button + .el-button {
      margin-left: 0px;
    }
    .el-button--mini {
      padding: 4px 4px;
      background: transparent;
      color: rgb(4, 87, 102);
      font-size: 14px;
      &:hover {
        border: 1px solid rgb(105, 105, 105);
      }
    }
    .no {
      color: green;
    }
    .off {
      color: red;
    }
    .el-input__inner {
      width: 50px;
      padding: 5px;
      text-align: center;
    }
  }
  // 页码
  .page {
    // .el-pagination {
    //   position: absolute;
    //   bottom: 0;
    //   right: 0;
    // }
    display: flex;
    justify-content: flex-end;
  }
  // 统计
  .statistics {
    background: rgb(217, 242, 247);
    margin-top: 5px;
    font-size: 14px;
    color: rgb(4, 87, 102);
    padding: 10px;
    .el-input {
      width: 55px;
    }
    .el-input__inner {
      width: 55px;
    }
  }
  // 按钮
  .btn {
    margin-top: 10px;
  }
}
</style>